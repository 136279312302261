<template>
  <div>
    <div class="md">
      <!-- <img src="../../../../static/private-b/wap.jpg" alt="活动页" style="width: 100%;" /> -->
    </div>

    <div class="lg">
      <!-- <img src="../../../../static/private-b/web.jpg" alt="活动页" style="width: 100%;" /> -->
      <a :href="'https://sem.rlcpluspro.com/subject/download?channel='+v" class="PCa"></a>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      v:this.$route.query.v||9912
    }
   
  }
}
</script>

<style scoped>
/*  Small devices (phones) */
@media only screen and (max-width: 768px) {
  .md {
    width: 100%;
    height: 6696px;
    background: url('../../../../static/private-b/wap.jpg') no-repeat center;
    background-size: 100% 100%;
    display: block;
  }

  .lg {
    display: none;
  }
}

/* Large devices (pc) */
@media only screen and (min-width: 769px) {
  .md {
    display: none;
  }

  .lg {
    width: 100%;
    height: 4420px;
    background: url('../../../../static/private-b/web.jpg') no-repeat center;
    background-size: 100% 100%;
    display: block;
    position: relative;
  }
  .PCa{
      width: 380px;
    height: 85px;
    position: absolute;
    top: 397px;
    right: 386px;
  }
}
</style>
